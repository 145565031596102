import { invokeApi } from "../../bl_libs/invokeApi";

export const login_api = async (data) => {
  const requestObj = {
    path: `api/teachers/login_teacher`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const member_init_api = async () => {
  const requestObj = {
    path: `api/teachers/member_init`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const login_by_admin_api = async (token) => {
  const requestObj = {
    path: `api/teachers/login_teacher_with_token/${token}`,
    method: "GET",
    headers: {
      // 'x-sh-auth': 1234
    },
  };
  return invokeApi(requestObj);
};
