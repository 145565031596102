import { Autocomplete, Paper, TextField } from '@mui/material';
import React, { useState } from 'react'

export default function MUIAutocomplete({ inputLabel, selectedOption, setSelectedOption, optionsList, multiple, autoComplete }) {
    const [inputValue, setInputValue] = useState("");

    return (
        <Autocomplete
            multiple={multiple}
            value={selectedOption}
            onChange={(event, newValue) => {
                setSelectedOption(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            className="w-100 mui-select autocomplete-mui"
            PaperComponent={({ children }) => (
                <Paper className="autocomplete-paper">{children}</Paper>
            )}
            id="controllable-states-demo"
            options={optionsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => {
                const inputProps = params.inputProps;
                inputProps.autoComplete = autoComplete;
                return <TextField {...params}
                    className="form-control"
                    label={inputLabel}
                    placeholder={inputLabel}
                />
            }
            }
        />
    )
}
