import React, { useContext, useState } from "react";
import { _get_user_from_localStorage } from "../localStorage/localStorage";
import { baseUrl } from "../config/config";
import io from "socket.io-client";

const CreateContentSetting = React.createContext();
const _get_user_info = _get_user_from_localStorage();

//----------------------

let socket = {};
// socket-initialization
socket = io(baseUrl + `?user_id=${_get_user_info?._id}&user_type=teacher`);

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [startedMeeting, setStartedMeeting] = useState({});
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(baseUrl + `?user_id=${user_id}&user_type=teacher`);
  };

  const STOP_SOCKET_FORCEFULLY = () => {
    socket.disconnect();
  };

  const collection = {
    userInfo,
    socket,
    unreadMessageCount,
    startedMeeting,
    setUserInfo,
    setStartedMeeting,
    setUnreadMessageCount,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
