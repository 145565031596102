import React, { useContext, useState } from "react";
import { useContentSetting } from "./ContentSetting";
import { useEffect } from "react";
import { tune_file, ended_call_tune } from "../assets";

const CreateClassRoom = React.createContext();
export const useClassRoom = () => useContext(CreateClassRoom);
export function ClassRoom({ children }) {
  const tune = new Audio(tune_file);
  const ended_call_file = new Audio(ended_call_tune);
  const [meetingId, setMeetingId] = useState();
  const [autoJoin, setAutoJoin] = useState(true);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [callUser, setCallUser] = useState("");
  const [startCallTune, setStartCallTune] = useState(tune);
  const [endedCallTune, setEndedCallTune] = useState(ended_call_file);
  const { socket, userInfo, startedMeeting } = useContentSetting();

  const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJjMzY0N2I3OC05ODg4LTRiNDUtYjg4Ny0xYjk2N2M5MWQ3OWEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY5Nzc1ODUxOCwiZXhwIjoxODU1NTQ2NTE4fQ.5rt_t1jlacNGbQCPrikN5v2TXkDA3Rd9KQsglCV-NiU";

  // API call to create meeting
  const createMeeting = async ({ token }) => {
    const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
      method: "POST",
      headers: {
        authorization: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    //Destructuring the roomId from the response
    const { roomId } = await res.json();
    return roomId;
  };

  const handleStartCall = async (conversation_id) => {
    let meeting_id = await createMeeting({ token: authToken });
    if (meeting_id) {
      setMeetingId(meeting_id);
    }
    socket.emit("user:call", {
      to: callUser,
      conversation_id,
      type: "teacher",
      sender_id: userInfo._id,
      meeting_id,
    });
  };

  const handleIncommingCall = async ({ from, meeting_id, message }) => {
    setCallUser(from);
    setMeetingId(meeting_id);
    setIsIncomingCall(true);
  };

  const handleStopAudio = () => {
    startCallTune.pause();
    startCallTune.currentTime = 0;
  };

  const handleCallAccepted = async ({ data }) => {
    handleStopAudio();
    setIsIncomingCall(false);
    setIsCallAccepted(true);
  };

  const handleCallRinging = async ({ data }) => {
    startCallTune.play();
  };

  useEffect(() => {
    socket.on("incomming:call", handleIncommingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("ringing:call", handleCallRinging);

    return () => {
      socket.off("incomming:call", handleIncommingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("ringing:call", handleCallRinging);
    };
  }, [callUser, socket]);

  useEffect(() => {
    if (startedMeeting) {
      setIsCallAccepted(true);
      setMeetingId(startedMeeting.meeting_id);
    }
  }, []);

  const collection = {
    meetingId,
    authToken,
    isIncomingCall,
    autoJoin,
    callUser,
    isCallAccepted,
    endedCallTune,
    startCallTune,
    setCallUser,
    handleStartCall,
    setIsIncomingCall,
    setMeetingId,
    handleStopAudio,
  };

  console.log(isCallAccepted, "isCallAcceptedisCallAccepted");

  return (
    <CreateClassRoom.Provider value={collection}>
      {children}
    </CreateClassRoom.Provider>
  );
}
